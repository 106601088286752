import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { neverError } from '@lis-helpers';
import { LisIcon, LisNotificationType } from '@lis-types';

@Component({
  selector: 'lis-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  @Input({ required: true }) type!: LisNotificationType;
  @Input() icon?: LisIcon;
  @Input() hasIcon = true;

  constructor() {}

  ngOnInit(): void {
    this.initIcon();
  }

  private initIcon(): void {
    if (!this.hasIcon) {
      this.icon = undefined;
    } else if (!this.icon) {
      this.icon = this.getDefaultIcon();
    }
  }

  private getDefaultIcon(): LisIcon {
    switch (this.type) {
      case 'info':
        return 'action_info-24';
      default:
        throw neverError(this.type);
    }
  }
}
