import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { LisFormField } from '@lis-form';
import { getUniqueId } from '@lis-helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit, OnDestroy {
  @Input({ required: true }) control!: FormControl;
  @Input({ required: true }) field!: LisFormField;
  @Input() isReadonly = false;

  public htmlId = getUniqueId();

  @Input() isOptionalVisible = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.control.parent?.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  private isTouched(): boolean {
    return !!this.control.touched;
  }

  public getErrors(): ValidationErrors | undefined {
    if (this.control?.errors) {
      return this.control?.errors;
    }

    return undefined;
  }

  public shouldShowErrors(): boolean {
    return (
      this.isTouched() &&
      !!this.getErrors() &&
      !this.control.disabled &&
      !this.isReadonly
    );
  }

  public getLabelClasses(): string[] {
    const classes: string[] = [];

    if (this.control.disabled) {
      classes.push('text-input-label-disabled');
    } else {
      classes.push('text-input-label');
    }

    return classes;
  }

  public getLabelHelperClasses(): string[] {
    const classes: string[] = [];

    if (this.control.disabled) {
      classes.push('text-input-helper-disabled');
    } else {
      classes.push('text-input-helper');
    }

    return classes;
  }
}
