<div>
  <label [for]="htmlId" class="flex items-baseline mb-1 typo-body-xs" *ngIf="field.label" [ngClass]="getLabelClasses()">
    <span class="mr-1" [innerHTML]="field.label | translate"></span>
    <span *ngIf="isOptionalVisible" [ngClass]="getLabelHelperClasses()">({{ 'form-field.optional' | translate }})</span>
  </label>

  <div class="grow">
    <ng-container *ngIf="field.type === 'text'">
      <div class="flex items-baseline space-x-2.5">
        <lis-input class="grow" [control]="control" [autocomplete]="field.autocomplete ?? ''" [placeholder]="field.placeholder ?? ''" [type]="field.textType ?? 'text'" [htmlId]="htmlId" [isReadonly]="isReadonly"></lis-input>
        <div class="text-input-text typo-body" *ngIf="field.postfix">{{ field.postfix }}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="field.type === 'textarea'">
      <lis-textarea [control]="control" [htmlId]="htmlId"></lis-textarea>
    </ng-container>

    <ng-container *ngIf="field.type === 'select'">
      <lis-select [control]="control" [options]="field.options" [placeholder]="field.placeholder" [htmlId]="htmlId"></lis-select>
    </ng-container>

    <ng-container *ngIf="field.type === 'checkbox'">
      <lis-checkbox [control]="control" [innerLabel]="field.innerLabel" [htmlId]="htmlId"></lis-checkbox>
    </ng-container>

    <ng-container *ngIf="field.type === 'checkbox-group'">
      <lis-checkbox-group [control]="control" [options]="field.options" [htmlId]="htmlId"></lis-checkbox-group>
    </ng-container>

    <ng-container *ngIf="field.type === 'radio-group'">
      <lis-radio-group [control]="control" [options]="field.options" [htmlId]="htmlId"></lis-radio-group>
    </ng-container>

    <ng-container *ngIf="field.type === 'toggle'">
      <lis-toggle [control]="control" [innerLabel]="field.innerLabel" [htmlId]="htmlId"></lis-toggle>
    </ng-container>

    <ng-container *ngIf="field.type === 'file-upload'">
      <lis-file-uploader [control]="control" [htmlId]="htmlId" [accept]="field.accept"></lis-file-uploader>
    </ng-container>

    <div *ngIf="shouldShowErrors() || field.info" class="mt-1.5 space-y-0.5">
      <div *ngIf="shouldShowErrors()">
        <lis-error [errors]="getErrors()"></lis-error>
      </div>
      <div *ngIf="field.info" [ngClass]="getLabelHelperClasses()">
        <lis-notification [type]="'info'">{{ field.info }}</lis-notification>
      </div>
    </div>
  </div>
</div>
